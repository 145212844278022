<template>
  <section id="conversation">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <h2>Página vazia</h2>

  </section>
</template>

<script>
import {
  BBreadcrumb,
} from 'bootstrap-vue'

export default {
  components: {
    BBreadcrumb,
  },
  data() {
    return {
      isLoading: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: 'cms-dashboard',
        },
        {
          text: 'Usuarios',
          active: true,
        },
      ],
    }
  },

  methods: {

  },
}
</script>
